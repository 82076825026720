<template>
    <el-container>
        <grid-table ref="gridTable" :tableConfig="tableConfig" @selectionChange="handleSelectionChange"
            :buttonConfig="buttonConfig" @handleButtonClick="callMethod" @editRow="handleShowDialog">
        </grid-table>
        <edit-dialog ref="editDialog" v-if="dialogVisible" :formConfig="editFormConfig"
            @handleQuery="handleQuery"></edit-dialog>
        <duty-auth ref="dutyAuth" v-if="dutyAuthDialogVisible" @handleQuery="handleQuery"></duty-auth>
    </el-container>
</template>

<script>
import { submit, exportFile } from "@/api/base";
import DutyAuth from "@/views/org/duty/components/DutyAuth.vue";
export default {
    name: "pageList",
    components: {
        DutyAuth
    },
    data() {
        return {
            routeParam: {},
            buttonConfig: [...(this.$route.meta.pageConfig.buttons || [])],
            tableConfig: { ...(this.$route.meta.pageConfig.table || {}) },
            selectRows: [],
            dialogVisible: false,
            dutyAuthDialogVisible: false,
            editFormConfig: {},
        };
    },
    mounted() {
        this.routeParam = {
            ...(this.$route.query || {}),
            ...(this.$route.params || {}),
        };
        this.$nextTick(async () => {
            if (this.tableConfig.searchForm.url) {
                const { data } = await submit(this.tableConfig.searchForm.url);
                const { items } = this.getFormRules(
                    this.tableConfig.searchForm.items,
                    data.InitData
                );
                this.$set(this.tableConfig.searchForm, "items", items);
            }
        });
    },
    activated() {
        var param = {
            ...(this.$route.query || {}),
            ...(this.$route.params || {}),
        };
        if (Object.keys(param).length > 0 && this.routeParam != param) {
            this.routeParam = param;
        }
    },
    watch: {
        routeParam: {
            deep: true,
            handler() {
                this.handleQuery();
            },
        },
    },
    methods: {
        handleQuery() {
            this.$refs.gridTable.fetchData(false, null, this.routeParam);
        },
        handleSelectionChange(selection) {
            this.selectRows = selection;
        },
        callMethod(button) {
            let methods = this.$options.methods;
            const _this = this;
            methods[button.method](_this, button);
        },
        handleSearch(_this, button) {
            _this.handleQuery();
        },
        handleDutyAuth(_this, button) {
            if (
                button.isNeedSelectRow &&
                (!_this.selectRows || _this.selectRows.length == 0)
            ) {
                _this.$baseMessage("请选择数据", "error");
                return false;
            }
            if (
                button.isNeedSelectRow &&
                !button.isMultiSelectRow &&
                _this.selectRows.length > 1
            ) {
                _this.$baseMessage("只能选择一行", "error");
                return false;
            }
            _this.dutyAuthDialogVisible = true;
            _this.$nextTick(() => {
                _this.editFormConfig = { ...button.bindForm };
                _this.$refs.dutyAuth.init(
                    button.label,
                    _this.getTableParam(
                        _this.selectRows,
                        button.isNeedRowKey,
                        button.isMultiSelectRow,
                        _this.tableConfig.rowKey,
                        button.paramJson
                    )
                );
            });
        },
        handleShowDialog(_this, button) {
            if (
                button.isNeedSelectRow &&
                (!_this.selectRows || _this.selectRows.length == 0)
            ) {
                _this.$baseMessage("请选择数据", "error");
                return false;
            }
            if (
                button.isNeedSelectRow &&
                !button.isMultiSelectRow &&
                _this.selectRows.length > 1
            ) {
                _this.$baseMessage("只能选择一行", "error");
                return false;
            }
            _this.dialogVisible = true;
            _this.$nextTick(() => {
                _this.editFormConfig = { ...button.bindForm };
                _this.$refs.editDialog.init(
                    button.label,
                    _this.getTableParam(
                        _this.selectRows,
                        button.isNeedRowKey,
                        button.isMultiSelectRow,
                        _this.tableConfig.rowKey,
                        button.paramJson
                    )
                );
            });
        },
        handleToExcute(_this, button) {
            if (
                button.isNeedSelectRow &&
                (!_this.selectRows || _this.selectRows.length == 0)
            ) {
                _this.$baseMessage(`请选择要${button.label}的数据`, "error");
                return false;
            }
            if (
                button.isNeedSelectRow &&
                !button.isMultiSelectRow &&
                _this.selectRows.length > 1
            ) {
                _this.$baseMessage("只能选择一行", "error");
                return false;
            }
            if (button.isNeedConfirm) {
                _this.$baseConfirm(`确定选中项要${button.label}吗？`, null, () => {
                    this.handleExecute(_this, button);
                });
            } else {
                this.handleExecute(_this, button);
            }
        },
        async handleExecute(_this, button) {
            const { msg } = await submit(
                button.url,
                _this.getTableParam(
                    _this.selectRows,
                    button.isNeedRowKey,
                    button.isMultiSelectRow,
                    _this.tableConfig.rowKey,
                    button.paramJson
                )
            );
            if (msg) {
                _this.$baseMessageBox(
                    `${button.label}成功，${msg}`,
                    `${button.label}提示`
                );
            } else {
                _this.$baseMessage(`${button.label}成功`, "success");
            }
            _this.handleQuery();
        },
        handleToGetAuthUrl(_this, button) {
            if (
                button.isNeedSelectRow &&
                (!_this.selectRows || _this.selectRows.length == 0)
            ) {
                _this.$baseMessage(`请选择要${button.label}的数据`, "error");
                return false;
            }
            if (_this.selectRows.length > 1) {
                _this.$baseMessage("只能选择一行", "error");
                return false;
            }
            _this.$baseConfirm(`确定选中项要${button.label}吗？`, null, () => {
                this.handleGetAuthUrl(_this, button);
            });
        },
        async handleGetAuthUrl(_this, button) {
            const { data, msg } = await submit(
                button.url,
                _this.getTableParam(
                    _this.selectRows,
                    button.isNeedRowKey,
                    button.isMultiSelectRow,
                    _this.tableConfig.rowKey,
                    button.paramJson
                )
            );
            _this.$baseMessageBox(
                `已打开授权页，请前往页面完成后续操作后刷新页面。`,
                `${button.label}提示`
            );
            window.open(data, "_blank");
            _this.handleQuery();
        },
        handleSkip(_this, button) {
            if (
                button.isNeedSelectRow &&
                (!_this.selectRows || _this.selectRows.length == 0)
            ) {
                _this.$baseMessage("请选择数据", "error");
                return false;
            }
            if (
                button.isNeedSelectRow &&
                !button.isMultiSelectRow &&
                _this.selectRows.length > 1
            ) {
                _this.$baseMessage("只能选择一行", "error");
                return false;
            }
            var params = _this.getTableParam(
                _this.selectRows,
                button.isNeedRowKey,
                button.isMultiSelectRow,
                _this.tableConfig.rowKey,
                button.paramJson
            );
            switch (button.skipType) {
                case "url":
                    window.open(button.url, "_blank");
                    break;
                case "routePage": {
                    const { href } = _this.$router.resolve({
                        name: button.toRouteName,
                        params: params,
                    });
                    window.open(href, "_blank");
                    break;
                }
                case "routeTab":
                    _this.$router.push({
                        name: button.toRouteName,
                        params: params,
                    });
                    break;
            }
        },
        async handleDownloadImportTemplate(_this, button) {
            const { data } = await submit(button.url);
            var a = document.createElement("a");
            a.href = data.Url;
            a.download = data.FileName;
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            a.remove();
        },
        async handleExportExcel(_this, button) {
            const { data } = await exportFile(button.url);
            const blob = new Blob([data.Blob], { type: "application/vnd.ms-excel" });
            var a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = data.FileName;
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            a.remove();
        },
    },
};
</script>
